<template>
    <div>
      <div class="container mx-auto my-10">
        <div class="flex flex-col md:flex-row gap-4 p-4 ml-2">
            <div class="border border-grey-2 rounded-xl w-full px-8 py-4 font-bold">
                <div class="flex flex-row justify-between mb-4">
                    <div class="">Form Waiver Unit</div>
                    <div class="flex gap-4">
                        <div class="" v-if="dropdownList.length > 0">
                            <button @click="showDropdownType = !showDropdownType" id="dropdownDefaultType" data-dropdown-toggle="dropdownType" class="text-gray-700 bg-gray-100 border border-gray-400 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center" type="button">{{activeType}}<svg class="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                            <!-- Dropdown menu -->
                            <div id="dropdownType" class="absolute z-10 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700" :class="{'hidden': !showDropdownType}">
                                <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultType">
                                    <li v-for="(type, idxtype) in dropdownList" :key="idxtype">
                                        <a href="#" @click="selectType(type)" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{ type.label }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="">
                            <button @click="showDropdownLang = !showDropdownLang" id="dropdownDefault" data-dropdown-toggle="dropdown" class="text-gray-700 bg-gray-100 border border-gray-400 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center" type="button">{{lang}}<svg class="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                            <!-- Dropdown menu -->
                            <div id="dropdown" class="absolute z-10 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700" :class="{'hidden': !showDropdownLang}">
                                <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                                <li>
                                    <a href="#" @click="selectLang('EN')" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">EN</a>
                                </li>
                                <li>
                                    <a href="#" @click="selectLang('ID')" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">ID</a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ValidationObserver v-slot="{ invalid }">
                <div class="flex flex-col gap-2 divide-y-2 divide-gray-300">
                    <div class="flex gap-8 font-bold text-xl">
                        <span>
                            {{dataLang.rider}}
                        </span>
                    </div>
                    <div class="flex flex-col md:grid md:grid-cols-2 gap-8 font-bold w-full pt-4">
                        <div class="flex flex-col gap-4">
                            <div class="w-full">
                                <label for="date" class="block mb-2 text-base font-bold">{{dataLang.date}}</label>
                                <ValidationProvider class="block mt-6" name="date" :rules="{ required: true }">
                                    <v-date-picker v-model="form.waiverRiderForm.date" ref="riderDateRef" :masks="{ L: 'DD/MM/YYYY' }">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <div class="mt-1 relative rounded-md">
                                            <input type="text" id="date" :value="inputValue" v-on="inputEvents" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="dd/mm/yy">
                                        </div>
                                    </template>
                                    </v-date-picker>
                                </ValidationProvider>
                            </div>
                            <div class="w-full">
                                <label for="rider_name" class="block mb-2 text-base font-bold">{{dataLang.name}}</label>
                                <ValidationProvider class="block mt-6" name="name" :rules="{ required: true }">
                                    <input type="text" id="rider_name" v-model="form.waiverRiderForm.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.rider_name">
                                </ValidationProvider>
                            </div>
                            <div class="w-full">
                                <label for="rider_phone" class="block mb-2 text-base font-bold">{{dataLang.phone_number}}</label>
                                <ValidationProvider class="block mt-6" name="phone" :rules="{ required: true, regex: /^[0-9]+$/, min:5, max: 20 }">
                                    <input type="text" id="rider_phone" v-model="form.waiverRiderForm.phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="0812xxxxxxx">
                                </ValidationProvider>
                            </div>
                            <div class="w-full">
                                <label for="rider_email" class="block mb-2 text-base font-bold">{{dataLang.email}}</label>
                                <ValidationProvider class="block mt-6" name="email" :rules="{ required: true }">
                                    <input type="email" id="rider_email" v-model="form.waiverRiderForm.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="user@tripwe.com">
                                </ValidationProvider>
                            </div>
                            <div class="w-full mt-6">
                                <div class="flex text-base gap-2 items-center">
                                    <div class="rounded-md border-2 border-primary grid items-center h-6 w-6 justify-items-center text-xl text-primary cursor-pointer" @click="form.withPassenger = !form.withPassenger">
                                        <font-awesome-icon icon="fa-solid fa-square-check" v-if="form.withPassenger"/>
                                    </div>
                                    <span>{{dataLang.with_passenger}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <div class="w-full">
                                <label for="rider_id_card_no" class="block mb-2 text-base font-bold">{{dataLang.id_card_passport}}</label>
                                <ValidationProvider class="block mt-6" name="id_card_no" :rules="{ required: false, regex: /^[0-9]+$/ }">
                                    <input type="text" id="rider_id_card_no" v-model="form.waiverRiderForm.id_card_no" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="3372***********">
                                </ValidationProvider>
                            </div>
                            <div class="flex flex-row gap-8">
                                <div class="w-full">
                                    <label for="rider_birth_place" class="block mb-2 text-base font-bold">{{dataLang.birth_place}}</label>
                                    <ValidationProvider class="block mt-6" name="birth_place" :rules="{ required: true }">
                                        <input type="text" id="rider_birth_place" v-model="form.waiverRiderForm.birth_place" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.city">
                                    </ValidationProvider>
                                </div>
                                <div class="w-full">
                                    <label for="rider_dob" class="block mb-2 text-base font-bold">{{dataLang.date_of_birth}}</label>
                                    <ValidationProvider class="block mt-6" name="dob" :rules="{ required: true }">
                                        <v-date-picker v-model="form.waiverRiderForm.dob" ref="riderDobRef" :max-date="maxDateRider" :masks="{ L: 'DD/MM/YYYY' }">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <div class="mt-1 relative rounded-md w-4/5">
                                                <input type="text" id="rider_dob" :value="inputValue" v-on="inputEvents" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="dd/mm/yy">
                                            </div>
                                        </template>
                                        </v-date-picker>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="w-full">
                                <label for="rider_emergency_contact" class="block mb-2 text-base font-bold">{{dataLang.emergency_contact}}</label>
                                <ValidationProvider class="block mt-6" name="emergency_contact" :rules="{ required: true, regex: /^[0-9]+$/, min:5, max: 20 }">
                                    <input type="text" id="rider_emergency_contact" v-model="form.waiverRiderForm.emergency_contact" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="0812xxxxxxxxx">
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col md:grid md:grid-cols-2 gap-8 font-bold w-full py-4" v-if="form.withPassenger">
                        <div class="flex flex-col gap-4">
                            <div class="w-full">
                                <label for="name" class="block mb-2 text-base font-bold">{{dataLang.name}}</label>
                                <ValidationProvider class="block mt-6" name="pname" :rules="{ required: form.withPassenger }">
                                    <input type="text" id="name" v-model="form.waiverPassengerForm.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.passenger_name">
                                </ValidationProvider>
                            </div>
                            <div class="w-full">
                                <label for="phone" class="block mb-2 text-base font-bold">{{dataLang.phone_number}}</label>
                                <ValidationProvider class="block mt-6" name="p_phone" :rules="{ required: form.withPassenger, regex: /^[0-9]+$/, min:5, max: 20 }">
                                    <input type="text" id="phone" v-model="form.waiverPassengerForm.phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="0812xxxxxxx">
                                </ValidationProvider>
                            </div>
                            <div class="w-full">
                                <label for="email" class="block mb-2 text-base font-bold">{{dataLang.email}}</label>
                                <ValidationProvider class="block mt-6" name="p_email" :rules="{ required: form.withPassenger }">
                                    <input type="email" id="email" v-model="form.waiverPassengerForm.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="user@tripwe.com">
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <div class="w-full">
                                <label for="id_card_no" class="block mb-2 text-base font-bold">{{dataLang.id_card_passport}}</label>
                                <ValidationProvider class="block mt-6" name="p_id_card_no" :rules="{ required: false, regex: /^[0-9]+$/ }">
                                    <input type="text" id="id_card_no" v-model="form.waiverPassengerForm.id_card_no" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="3372***********">
                                </ValidationProvider>
                            </div>
                            <div class="flex flex-row gap-8">
                                <div class="w-full">
                                    <label for="birth_place" class="block mb-2 text-base font-bold">{{dataLang.birth_place}}</label>
                                    <ValidationProvider class="block mt-6" name="p_birth_place" :rules="{ required: form.withPassenger}">
                                        <input type="text" id="birth_place" v-model="form.waiverPassengerForm.birth_place" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.city">
                                    </ValidationProvider>
                                </div>
                                <div class="w-full">
                                    <label for="date_of_birth" class="block mb-2 text-base font-bold">{{dataLang.date_of_birth}}</label>
                                    <ValidationProvider class="block mt-6" name="p_dob" :rules="{ required: form.withPassenger }">
                                        <v-date-picker v-model="form.waiverPassengerForm.dob" ref="passengerDobRef" :max-date="maxDatePassenger" :masks="{ L: 'DD/MM/YYYY' }">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <div class="mt-1 relative rounded-md w-4/5">
                                                <input type="text" id="date_of_birth" :value="inputValue" v-on="inputEvents" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="dd/mm/yy">
                                            </div>
                                        </template>
                                        </v-date-picker>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="w-full">
                                <label for="emergency_contact" class="block mb-2 text-base font-bold">{{dataLang.emergency_contact}}</label>
                                <ValidationProvider class="block mt-6" name="p_emergency_contact" :rules="{ required: true }">
                                    <input type="text" id="emergency_contact" v-model="form.waiverPassengerForm.emergency_contact" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="0812xxxxxxxxx">
                                </ValidationProvider>
                            </div>
                            <div class="w-full font-light text-sm">
                                <p>{{dataLang.left_blank}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col font-bold w-full py-4">
                        <div class="flex flex-row justify-between text-primary cursor-pointer" @click="tab.rule = !tab.rule"><span>{{textOnRule}}</span> <font-awesome-icon icon="fa-solid fa-caret-up" v-if="tab.rule"/><font-awesome-icon icon="fa-solid fa-caret-down" v-else/></div>
                        <div class="py-4 px-6" v-if="tab.rule">
                            <ol class="list-decimal">
                                <li v-for="(rule, idxrule) in filterRules" :key="idxrule">
                                    <p class="text-base">
                                        {{rule.rule_text}}
                                        <ol class="list-disc pl-6">
                                            <li v-for="(child, idxchild) in rule.childrens" :key="idxchild">
                                                <p class="text-base">
                                                    {{child.rule_text}}
                                                </p>
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="flex flex-col font-bold w-full py-4">
                        <div class="flex flex-row justify-between text-primary cursor-pointer" @click="tab.disclaimer = !tab.disclaimer"><span>{{dataLang.disclaimer}}</span> <font-awesome-icon icon="fa-solid fa-caret-up" v-if="tab.disclaimer"/><font-awesome-icon icon="fa-solid fa-caret-down" v-else/></div>
                        <div class="py-4 px-6" v-if="tab.disclaimer">
                            <ol class="list-disc">
                                <li v-for="(rule, idxrule) in filterDisclaimer" :key="idxrule">
                                    <p class="text-base">
                                        {{rule.rule_text}}
                                        <ol class="list-disc pl-6">
                                            <li v-for="(child, idxchild) in rule.childrens" :key="idxchild">
                                                <p class="text-base">
                                                    {{child.rule_text}}
                                                </p>
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                            </ol>
                            <p class="text-base py-4" v-html="nl2br(dataRules.penutup[0].rule_text)"></p>
                        </div>
                    </div>
                    <div class="flex flex-col font-bold w-full py-4">
                        <div class="flex flex-row justify-between text-primary cursor-pointer" @click="tab.declare = !tab.declare"><span>{{dataLang.i_declare}}</span> <font-awesome-icon icon="fa-solid fa-caret-up" v-if="tab.declare"/><font-awesome-icon icon="fa-solid fa-caret-down" v-else/></div>
                        <div class="py-4 px-6" v-if="tab.declare">
                            <ol class="list-decimal py-4">
                                <li v-for="(rule, idxrule) in filterStatement" :key="idxrule">
                                    <p class="text-base">
                                        {{rule.rule_text}}
                                        <ol class="list-disc pl-6">
                                            <li v-for="(child, idxchild) in rule.childrens" :key="idxchild">
                                                <p class="text-base">
                                                    {{child.rule_text}}
                                                </p>
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                            </ol>
                            <p class="text-base py-4" v-html="nl2br(dataRules.statement_penutup[0].rule_text)">
                            </p>
                        </div>
                    </div>
                    <div class="flex flex-col gap-4 pt-4">
                        <div class="w-full">
                            <div class="flex flex-row text-sm gap-2 items-center">
                                <div class="rounded-md border-2 border-primary grid items-center h-6 w-6 justify-items-center text-xl text-primary cursor-pointer" @click="form.iHaveRead = !form.iHaveRead">
                                    <font-awesome-icon icon="fa-solid fa-square-check" v-if="form.iHaveRead"/>
                                </div>
                                <span class="flex-1">{{dataLang.i_have_read}}</span>
                            </div>
                        </div>
                        <div class="w-full">
                            <div class="flex flex-row text-sm gap-2 items-center">
                                <div class="rounded-md border-2 border-primary grid items-center h-6 w-6 justify-items-center text-xl text-primary cursor-pointer" @click="form.iAgree = !form.iAgree">
                                    <font-awesome-icon icon="fa-solid fa-square-check" v-if="form.iAgree"/>
                                </div>
                                <span class="flex-1">{{dataLang.i_agree_statement}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col gap-4 pt-4">
                        <div class="w-full grid justify-items-center" v-if="!isUpdate">
                            <VueSignaturePad width="400px" height="250px" ref="signaturePad" class="rounded-xl border-2 border-grey-400" />
                            <button class="button-danger text-lg mt-5 px-10 mx-auto" type="button" @click="clear">Clear</button>
                        </div>
                        <button :disabled="invalid" class="button-submit text-lg px-10 mx-auto" type="button" @click="send">{{dataLang.send}}</button>
                    </div>
                </div>
                </ValidationObserver>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
  import Breadcrumb from '@/components/layout/Breadcrumb.vue';
  import langID from '@/locale/id.json';
  import langEN from '@/locale/en.json';

  export default {
    name: 'Waiver',
    components: {
      Breadcrumb,
    },
    data: () => ({
      listWaiver: [],
      selectedWaiver: {},
      showDropdownLang: false,
      showDropdownType: false,
      lang: "EN",
      type: '',
      tab: {
        rule: true,
        disclaimer: false,
        declare: false,
      },
      isUpdate: false,
      form:{
            useMyData: false,
            withPassenger: false,
            iHaveRead: false,
            iAgree: false,
            waiverRiderForm: {
                id: "",
                date: "",
                name: "",
                phone: "",
                email: "",
                id_card_no: "",
                birth_place: "",
                dob: "",
                emergency_contact: "",
            },
            waiverPassengerForm: {
                id: "",
                name: "",
                phone: "",
                email: "",
                id_card_no: "",
                birth_place: "",
                dob: "",
                emergency_contact: "",
            },
      },
      formDefault:{
            useMyData: false,
            withPassenger: false,
            iHaveRead: false,
            iAgree: false,
            waiverRiderForm: {
                id: "",
                date: "",
                name: "",
                phone: "",
                email: "",
                id_card_no: "",
                birth_place: "",
                dob: "",
                emergency_contact: "",
            },
            waiverPassengerForm: {
                id: "",
                name: "",
                phone: "",
                email: "",
                id_card_no: "",
                birth_place: "",
                dob: "",
                emergency_contact: "",
            },
      },
      dataWaiver: {},
      dataTrx: {},
      dataRules: {},
    }),
    async created () {
        if(this.$store.state.user.status_onroad == 1){
            this.type = '3';
        }
        if(this.$store.state.user.status_canam_safari == 1){
            this.type = '2';
        }
        if(this.$store.state.user.status_jetski_safari == 1 || this.$store.state.user.status_jetski_rental == 1){
            this.type = '1';
        }
        
        await this.$nextTick();
        this.loadWaiverRule();
        if(this.$route.params?.waiver != null){
            this.isUpdate = true;
            this.form.waiverRiderForm.id = this.$route.params?.waiver.id;
            this.form.waiverRiderForm.date = this.$route.params?.waiver.tanggal;
            this.form.waiverRiderForm.name = this.$route.params?.waiver.name;
            this.form.waiverRiderForm.phone = this.$route.params?.waiver.phone_number;
            this.form.waiverRiderForm.email = this.$route.params?.waiver.email;
            this.form.waiverRiderForm.id_card_no = this.$route.params?.waiver.nik;
            this.form.waiverRiderForm.birth_place = this.$route.params?.waiver.pob;
            this.form.waiverRiderForm.dob = this.$route.params?.waiver.dob;
            this.form.waiverRiderForm.emergency_contact = this.$route.params?.waiver.emergency;
            if(Object.keys(this.$route.params?.waiver?.passenger).length > 0){
                this.form.withPassenger = true;
                this.form.waiverPassengerForm.id = this.$route.params?.waiver?.passenger?.id;
                this.form.waiverPassengerForm.name = this.$route.params?.waiver?.passenger?.name;
                this.form.waiverPassengerForm.phone = this.$route.params?.waiver?.passenger?.phone_number;
                this.form.waiverPassengerForm.email = this.$route.params?.waiver?.passenger?.email;
                this.form.waiverPassengerForm.id_card_no = this.$route.params?.waiver?.passenger?.nik;
                this.form.waiverPassengerForm.birth_place = this.$route.params?.waiver?.passenger?.pob;
                this.form.waiverPassengerForm.dob = this.$route.params?.waiver?.passenger?.dob;
                this.form.waiverPassengerForm.emergency_contact = this.$route.params?.waiver?.passenger?.emergency;
            }
        }
    },
    computed: {
        dataLang(){
            if(this.lang == "EN"){
                return langEN;
            }else{
                return langID;
            }
        },
        activeType(){
            if(this.type == '1'){
                return 'Jetski';
            }
            if(this.type == '2'){
                return 'Off-Road';
            }
            if(this.type == '3'){
                return 'On-Road';
            }
        },
        textOnRule(){
            if(this.type == '1'){
                return this.dataLang.rules_order_label;
            }
            if(this.type == '2'){
                return this.dataLang.rules_order_label_canam;
            }
            if(this.type == '3'){
                return this.dataLang.rules_order_label_canam;
            }
        },
        maxDateRider(){
            return this.$moment().subtract(15, 'years').format('YYYY-MM-DD');
        },
        maxDatePassenger(){
            return this.$moment().subtract(13, 'years').format('YYYY-MM-DD');
        },
        filterRules(){
            return this.dataRules.peraturan;
        },
        filterDisclaimer(){
            return this.dataRules.perhatian;
        },
        filterStatement(){
            return this.dataRules.statement;
        },
        checkValid(){
            var valid = true;
            if(!this.form.iHaveRead){
                valid = false;
            }
            if(!this.form.iAgree){
                valid = false;
            }
            if(this.form.waiverRiderForm.phone == this.form.waiverRiderForm.emergency_contact){
                valid = false;
            }
            if(this.form.withPassenger && this.form.waiverPassengerForm.phone == this.form.waiverPassengerForm.emergency_contact){
                valid = false;
            }
            return valid;
        },
        dropdownList(){
            let list = [];
            if(this.$store.state.user.status_jetski_safari == 1 || this.$store.state.user.status_jetski_rental == 1){
                list.push({id: 1, label: "Jetski"});
            }
            if(this.$store.state.user.status_canam_safari == 1){
                list.push({id: 2, label: "Off-Road"});
                if(list.length <= 0){
                    this.type == '2';
                }
            }
            if(this.$store.state.user.status_onroad == 1){
                list.push({id: 3, label: "On-Road"});
                if(list.length <= 0){
                    this.type == '3';
                }
            }
            return list;
        }
    },
    methods: {
        nl2br (str, is_xhtml) {
            if (typeof str === 'undefined' || str === null) {
                return '';
            }
            var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        },
        selectLang(lang){
            this.lang = lang;
            this.showDropdownLang = false;
            this.loadWaiverRule();
        },
        selectType(type){
            this.type = type.id;
            this.showDropdownType = false;
            this.loadWaiverRule();
        },
        async loadWaiverRule() {   
            this.$store.commit("setOverlayLoading", true);
            try {
                var reqURL = `${this.$apiTripweWeb}/waiver/rule`;
                var param = {
                    idtoko: this.$store.state.user.id_user ?? "",
                    type: this.type,
                    lang: this.lang,
                };

                const res = await this.$http.post( reqURL, param);

                if(res.data.status == "200"){
                    this.dataRules = res.data.response;
                }
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit("setOverlayLoading", false);
            }
        },
        validate(){
            if(!this.form.iHaveRead){
                this.$toasted.global.error("Please check all data");
                return false;
            }
            if(!this.form.iAgree){
                this.$toasted.global.error("Please check all data");
                return false;
            }
            if(this.form.waiverRiderForm.phone == this.form.waiverRiderForm.emergency_contact){
                this.$toasted.global.error("Rider's emergency phone numbers cannot be the same as the rider's phone number");
                return false;
            }
            if(this.form.withPassenger && this.form.waiverPassengerForm.phone == this.form.waiverPassengerForm.emergency_contact){
                this.$toasted.global.error("Passenger's emergency phone numbers cannot be the same as the passenger's phone number");
                return false;
            }
            return true;
        },
        clear(){
            this.$refs.signaturePad.clearSignature();
        },
        saveSignature(){
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            return;
        },
        async send() {
            var valid = this.validate();
            if(!valid){
                return false;
            }
            
            let image = null;
            if(!this.isUpdate){
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                image = data;
                if(!this.isUpdate && isEmpty){
                    this.$toasted.global.error("Please sign the form");
                    return false;
                }
            }
            
            this.$store.commit("setOverlayLoading", true);
            try {
                var reqURL;
                var param = {};
                var paramPassenger = {};
                if(this.isUpdate){
                    reqURL     = `${this.$apiTripweWeb}/waiver-admin/update/${this.form.waiverRiderForm.id}`;
                }else{
                    reqURL     = `${this.$apiTripweWeb}/waiver-admin/insert`;
                }
                param = {
                    "tanggal"   : this.$moment(this.form.waiverRiderForm.date).format("YYYY-MM-DD"),
                    "tipeunit"  : "1",
                    "nama"      : this.form.waiverRiderForm.name,
                    "telepon"   : this.form.waiverRiderForm.phone,
                    "email"     : this.form.waiverRiderForm.email,
                    "nik"       : this.form.waiverRiderForm.id_card_no,
                    "dob"       : this.$moment(this.form.waiverRiderForm.dob).format("YYYY-MM-DD"),
                    "pob"       : this.form.waiverRiderForm.birth_place,
                    "emergency" : this.form.waiverRiderForm.emergency_contact,
                    "signature" : image ?? null,
                    "passenger" : {},
                }
                if(this.form.withPassenger){
                    paramPassenger = {
                        "tipeunit"  : "2",
                        "nama"      : this.form.waiverPassengerForm.name,
                        "telepon"   : this.form.waiverPassengerForm.phone,
                        "email"     : this.form.waiverPassengerForm.email,
                        "nik"       : this.form.waiverPassengerForm.id_card_no,
                        "dob"       : this.$moment(this.form.waiverPassengerForm.dob).format("YYYY-MM-DD"),
                        "pob"       : this.form.waiverPassengerForm.birth_place,
                        "emergency" : this.form.waiverPassengerForm.emergency_contact,
                    }
                    param.passenger = paramPassenger;
                }

                const res = await this.$http.post( reqURL, param);

                if(res.data.status == "200"){
                    this.form = this.formDefault;
                    if(this.isUpdate){
                        this.$toasted.global.success(`Waiver Updated`);
                    }else{
                        this.$toasted.global.success(`Waiver Created`);
                    }
                    this.$router.push({name: 'WaiverAdminList'});
                }else{
                    throw res.data.message;
                }
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit("setOverlayLoading", false);
            }
        }
    }
  }
</script>